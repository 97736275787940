import React, { startTransition, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import './style/style.css'
import { useTranslation } from 'react-i18next';

const Sidebar = ({onIconClick}) => {
  // const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const navigate = useNavigate()
  const[isChartsOpen, setIsChartsOpen] = useState(false);
  const[isActivityOpen, setIsActivityOpen] = useState(false);
  const[isProjectOpen, setIsProjectOpen] = useState(false);
  const [user,setUser]= useState(JSON.parse(localStorage.getItem('token')))
  const [Role, setRole]= useState(JSON.parse(localStorage.getItem('token'))?.role_access_data)
  const toggleCharts = () => {
    setIsChartsOpen(!isChartsOpen);// Close Dashboard dropdown if open
  };
  const toggleActivity =() => {
    setIsActivityOpen(!isActivityOpen)
  }
  const toggleProject =() => {
    // alert("works")
    setIsProjectOpen(!isProjectOpen)
  }
  const { t } = useTranslation();
  const toggleDataAttribute = () => {
    const htmlElement = document.documentElement;

    // Toggle the value of the data-toggled attribute
    if (htmlElement.getAttribute('data-toggled') === 'open') {
      htmlElement.setAttribute('data-toggled', 'close');
    } else {
      htmlElement.setAttribute('data-toggled', 'open');
    }
  };

  const handleIconClickvalue = () => {
    if (window.innerWidth < 768) {
      toggleDataAttribute();
    }
  };


  
const Sidebar = [
  {
    Name:t('User_Management'),
    img:"/images/icons/activity.png",
    route:'0'
  },
  {
    Name:t('Activity_Tracking'),
    img:"/images/icons/activity.png",
    route:'1'
  },
  {
    Name:t('Project_Management'),
    img:"/images/icons/teamwork.png",
    route:'2'
  },
  {
    Name:t('Attendence'),
    img:"/images/icons/attendance.png",
    route:'/attendence'
  },
  {
    Name:t('Timesheet'),
    img:"/images/icons/calendar.png",
    route:'/timesheet'
  }
]



  const [isRotating, setIsRotating] = useState(false);

  const handleIconClick = () => {
    onIconClick();
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 5000); 
  };


  const UserManagement = [
    {
      Name: t('Department'),
      img: "/images/icons/it-department.png",
      route: '/department'
    },
    {
      Name: t('Sub_Department'),
      img: "/images/icons/sub_depart.png",
      route: '/sub_depart'
    },
    {
      Name: t('Role_Access'),
      img: "/images/icons/roles_access.png",
      route: '/role-access'
    },
    {
      Name: t('Employee'),
      img: "/images/icons/employee.png",
      route: '/employee'
    }
  ];
  const Activity = [
    {
      Name: t('Mouse_Keyboard'),
      img: "/images/icons/keyboard.png",
      route: '/keystroke'
    },
    {
      Name: t('Application'),
      img: "/images/icons/activity.png",
      route: '/application-track'
    },
    {
      Name: t('URL'),
      img: "/images/icons/urls.png",
      route: '/Url-track'
    },
    {
      Name: t('Screenshots'),
      img: "/images/icons/capture.png",
      route: '/screen-shots'
    },
    {
      Name: t('Location'),
      img: "/images/icons/location.png",
      route: '/locations'
    }
  ];

  const ProjectManagement = [

    {
      Name: t('Client'),
      img: "/images/icons/client.png",
      route: '/client'
    },
    {
      Name: t('Projects'),
      img: "/images/icons/managment.png",
      route: '/project'
    },
    {
      Name: t('Project_Teams'),
      img: "/images/icons/team_project.png",
      route: '/add-project'
    },
    {
      Name: t('To_Dos'),
      img: "/images/icons/todo.png",
      route: '/todo'
    }
  ];

  return (
    <aside className="app-sidebar" id="sidebar">
      <div className="main-sidebar-header">
        <a className="header-logo">
          <img
            src="/assets/images/brand-logos/applogo.png"
            alt="logo"
            className="desktop-logo"
            style={{ width: "100px", height: "100px" }}
          />
          <img
            src="../assets/images/brand-logos/toggle-logo.png"
            alt="logo"
            className="toggle-logo"
          />
          <img
            src="../assets/images/brand-logos/desktop-dark.png"
            alt="logo"
            className="desktop-dark"
          />
          <img
            src="../assets/images/brand-logos/toggle-dark.png"
            alt="logo"
            className="toggle-dark"
          />
          <img
            src="../assets/images/brand-logos/desktop-white.png"
            alt="logo"
            className="desktop-white"
          />
        </a>
      </div>
      <div className="main-sidebar" id="sidebar-scroll">
        {/* <div className="d-flex justify-content-center" style={{borderBottom:"px solid #f9f9f9"}}>
      <img
            src="/assets/images/brand-logos/applogo.png"
            alt="logo"
            className="desktop-logo"
            style={{ width: "100px", height: "100px" }}
          />
          </div> */}
        <div className="w-100 px-4 py-2">        
          <span className="side-menu__label">Hi, {user?.name}</span>
        </div>
        <nav className="main-menu-container nav nav-pills flex-column sub-open">
          <div className="slide-left" id="slide-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width={24}
              height={24}
              viewBox="0 0 24 24"
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
            </svg>
          </div>
         <ul className="main-menu">
         <li className="slide" onClick={()=>{startTransition(()=>{
          navigate('/dashboard')})}} >
                    <a className="side-menu__item gap-3" onClick={()=>{handleIconClickvalue()}}>
                      <img src='/images/icons/activity.png' style={{ width: 25, height: 25 }} alt="Employee" />
                      <span className="side-menu__label"> {t('dashboard')}</span>
                    </a>
                  </li>

      {Sidebar.map((item, index) => (
        <li className="slide" key={index}>
        {UserManagement.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "0"?(
            <>
              <a
                className="side-menu__item"
                onClick={()=>toggleCharts()}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="side-menu__icon"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M19 5H5v14h14V5zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
                    opacity=".3"
                  />
                  <path d="M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2zm2 0h14v14H5V5zm2 5h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
                </svg> */}
                <img src="/images/icons/user.png" className="mr-2" alt="" style={{ width: 30, height: 30 }} />
                <span className="side-menu__label">{item.Name}</span>
                <i className={`fe fe-chevron-right side-menu__angle ${isChartsOpen ? 'rotate' : ''}`} />
              </a>
                <ul className={`slide-menu ${isChartsOpen ? 'd-block' : 'd-none'} child1`}>
                  <li className="slide side-menu__label1">
                    <a>Charts</a>
                  </li>
                  {UserManagement.filter((data, index) => Role?.menu?.includes(data.route)).map(item=>(
                  <li className="slide" onClick={()=>{startTransition(()=>{
                    navigate(item.route)})}}>
                    <a className="side-menu__item gap-3" onClick={()=>{handleIconClickvalue()}}>
                      <img src={item.img} style={{ width: 25, height: 25 }} alt="Employee" />
                      <span className="side-menu__label">{item.Name}</span>
                    </a>
                  </li>
                  ))}
                </ul>
            </>
          ) : Activity.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "1" ? (
            <>
              <a className="side-menu__item" onClick={()=>toggleActivity()}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="side-menu__icon"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M19 5H5v14h14V5zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
                    opacity=".3"
                  />
                  <path d="M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2zm2 0h14v14H5V5zm2 5h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
                </svg> */}
                 <img src="/images/icons/activity.png" className="mr-2" alt="" style={{ width: 30, height: 30 }} />
                <span className="side-menu__label">{item.Name}</span>
                <i className={`fe fe-chevron-right side-menu__angle ${isChartsOpen ? 'rotate' : ''}`} />
              </a>
                <ul className={`slide-menu ${isActivityOpen ? 'd-block' : 'd-none'} child1`}>
                  <li className="slide side-menu__label1">
                    <a >Charts</a>
                  </li>
                
                  {Activity.filter((data, index) => Role?.menu?.includes(data.route)).map(item=>(
                  <li className="slide" onClick={()=>{startTransition(()=>{
                    navigate(item.route)})}}>
                    <a className="side-menu__item gap-3" onClick={()=>{handleIconClickvalue()}}>
                      <img src={item.img} style={{ width: 25, height: 25 }} alt="Employee" />
                      <span className="side-menu__label">{item.Name}</span>
                    </a>
                  </li>
                  ))}
                </ul>
            </>
          ): ProjectManagement.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "2" ? (
            <>
            <a className="side-menu__item" onClick={()=>toggleProject()}>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="side-menu__icon"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M19 5H5v14h14V5zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
                  opacity=".3"
                />
                <path d="M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2zm2 0h14v14H5V5zm2 5h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
              </svg> */}
              <img src="/images/icons/project.png" className="mr-2" alt="" style={{ width: 30, height: 30 }} />
              <span className="side-menu__label">{item.Name}</span>
              <i className={`fe fe-chevron-right side-menu__angle ${isChartsOpen ? 'rotate' : ''}`} />
            </a>
              <ul className={`slide-menu ${isProjectOpen ? 'd-block' : 'd-none'} child1`}>
                <li className="slide side-menu__label1">
                  <a >Charts</a>
                </li>
              
                {ProjectManagement.filter((data, index) => Role?.menu?.includes(data.route)).map(item=>(
                <li className="slide" onClick={()=>{startTransition(()=>{
                  navigate(item.route)})}}>
                  <a className="side-menu__item gap-3" onClick={()=>{handleIconClickvalue()}}>
                    <img src={item.img} style={{ width: 30, height: 30 }} alt="Employee" />
                    <span className="side-menu__label">{item.Name}</span>
                  </a>
                </li>
                ))}
              </ul>
          </>
          ): Sidebar.find(data=>Role?.menu?.includes(data.route) && item.route == data.route) && (
            <a 
            onClick={() => {
              handleIconClickvalue(); // Toggle the attribute
              startTransition(() => {
                navigate(item.route); // Then navigate
              });
            }}  className="side-menu__item gap-3">
              <img src={item.img} style={{ width: 30, height: 30}} alt={item.Name} />
              <span className="side-menu__label" >{item.Name}</span>
            </a>
          )}
        </li>
      ))}

    </ul>
          <div className="slide-right" id="slide-right">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24">
              <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
            </svg> */}
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
