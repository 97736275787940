export const english = {
  welcome: 'Welcome',
  dashboard:'Dashboard',
  welcomedashboard: 'Hi, welcome back!',
  Employee_Report: 'Employee Report dashboard.',
  TODAY_WORKING_HOURS: 'TODAY WORKING HOURS',
  TOTAL_IDLE_HOURS: 'TOTAL IDLE HOURS',
  Mouse_Count: 'Mouse Count',
  Keystroke_Count: 'Keystroke Count',
  User_Management: 'User Management',
  Activity_Tracking: 'Activity Tracking',
  Project_Management: 'Project Management',
  Attendence: 'Attendance',
  Timesheet: 'Timesheet',
  Department: 'Department',
  Sub_Department: 'Sub Department',
  Role_Access: 'Role Access',
  Employee: 'Employee',
  Mouse_Keyboard: 'Mouse & Keyboard',
  Application: 'Application',
  URL: 'URL',
  Screenshots: 'Screenshots',
  Location: 'Location',
  Projects: 'Projects',
  Client: 'Client',
  Project_Teams: 'Project Teams',
  To_Dos: 'To-dos',
  Previous:'Previous',
  Previous_Mouse_click:'Previous Mouse click',
  Previous_Key_Count:'Previous Key Count',
  Keystroke_Count:'Keystroke Count',
  Hours_spend_today:'Hours spend today',
  paragraph:'Visualize and analyze employee work hours for better time management and productivity.',
  Working_hours:' Working hours',
  Idle_hours:' Idle hours',
  Weekly_Report:'Weekly Report',
  paragraph1:'Report Status, Track your employee weekly report the basis of hours per day.',
  Manual_hours:'Manual hours'







};

  
export const arabic = {
  welcome: 'مرحباً',
  dashboard:'لوحة القيادة',
  welcomedashboard: 'مرحبا، مرحبا بكم مرة أخرى!',
  Employee_Report: 'لوحة تقارير الموظف.',
  TODAY_WORKING_HOURS: 'ساعات العمل اليوم',
  TOTAL_IDLE_HOURS: 'إجمالي ساعات الخمول',
  Mouse_Count: 'عدد الماوس',
  Keystroke_Count: 'عدد ضربات المفاتيح',
  User_Management: 'إدارة المستخدم',
  Activity_Tracking: 'تتبع الأنشطة',
  Project_Management: 'إدارة المشاريع',
  Attendence: 'الحضور',
  Timesheet: 'سجل الوقت',
  Department: 'القسم',
  Sub_Department: 'الفرع',
  Role_Access: 'صلاحيات الأدوار',
  Employee: 'الموظف',
  Mouse_Keyboard: 'الفأرة ولوحة المفاتيح',
  Application: 'التطبيق',
  URL: 'رابط',
  Screenshots: 'لقطات الشاشة',
  Location: 'الموقع',
  Projects: 'المشاريع',
  Client: 'العميل',
  Project_Teams: 'فرق المشروع',
  To_Dos: 'المهام',
  Previous:'سابق',
  Previous_Mouse_click:'النقر السابق بالماوس',
  Previous_Key_Count:'عدد المفاتيح السابق',
  Keystroke_Count:'عدد ضربات المفاتيح',
  Hours_spend_today:'ساعات تقضي اليوم',
  paragraph:'تصور وتحليل ساعات عمل الموظف لتحسين إدارة الوقت والإنتاجية.',
  Working_hours:'ساعات العمل',
  Idle_hours:'ساعات الخمول',
  Weekly_Report:'التقرير الأسبوعي',
  paragraph1:'حالة التقرير، تتبع التقرير الأسبوعي لموظفك على أساس ساعات العمل في اليوم.',
  Manual_hours:'ساعات يدوية',




};

  