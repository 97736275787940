import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import FetchData from "../fetch-api/Apifetch";
import { LIST_ACTIVITY, LIST_REPORT } from "./ApiRoute";


export const handleImageError = (event) => {
    event.target.src = `/images/imageplaceholder.jpeg`; // path to your default image
  };
export const handleProImageError = (event) => {
    event.target.src = `/images/images.png`; // path to your default image
  };


export const TimeCalculator = ({ application, onTimeCalculated }) => {
  // Function to convert time format to seconds
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Function to convert seconds to time format
  const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (application.Total_Usage) {
      // Convert Total_Usage time to seconds
      const usageInSeconds = timeToSeconds(application.Total_Usage);

      // Convert seconds to time format
      const formattedTime = secondsToTime(usageInSeconds);

      // Call the parent function with the calculated time
      onTimeCalculated(formattedTime, usageInSeconds);
    }
  }, [application, onTimeCalculated]);

  return null; // No UI needed as we only calculate and return the value
};


export const ActivityTrackingApi = async () => {
  // get user data through localstorage and set it 
  const user = 1;
  try{
    const res  = await FetchData(LIST_ACTIVITY+`?page=1&limit=7`,'GET', null, false, false);
    console.log(res);
  }catch(err){
    console.log(err);
  }


}

export const Listicons = () => {

    const location = useLocation()


  return(

        
<>
      <a href='/application-track' className={`${location.pathname == '/application-track' ? 'border-b-[0.25rem]': ''}`}><img src="/images/icons/activity.png" style={{width:'30px',height:'30px'}}/></a>
      <a href='/Url-track' className={`${location.pathname == '/Url-track' ? 'border-b-[0.25rem]': ''}`}><img src="/images/icons/urls.png" style={{width:'30px',height:'30px'}}/></a>
      <a href='/screen-shots' className={`${location.pathname == '/screen-shots' ? 'border-b-[0.25rem]': ''}`}><img src="/images/icons/capture.png" style={{width:'30px',height:'30px'}}/></a>
      <a href='/keystroke' className={`${location.pathname == '/keystroke' ? 'border-b-[0.25rem]': ''}`}><img src="/images/icons/keyboard.png" style={{width:'30px',height:'30px'}}/></a>
      <a href='/locations' className={`${location.pathname == '/locations' ? 'border-b-[0.25rem]': ''}`}><img src="/images/icons/location.png" style={{width:'30px',height:'30px'}}/></a>
      </>
  )
}