import React, { Suspense, useState,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "./common/Header";
import { Shimmer } from "./common/Shimmer";
import 'react-loading-skeleton/dist/skeleton.css';
import Sidebar from "./common/Sidebar";

const Login = React.lazy(() => import("./common/Login"));
const Content = React.lazy(() => import("./split/Dashboard/Content"));
const ScreenShots = React.lazy(() => import("./split/ScreenShot/Screenshots"));
const Application_act = React.lazy(() => import("./split/Application_activity"));
const Organization = React.lazy(() => import("./split/Organization"));
const URL_Track = React.lazy(() => import("./split/Url-Track"));
const Organization_details = React.lazy(() => import("./split/Organization_details"));
const Attendence = React.lazy(() => import("./split/Attendence/Attendence"));
const Department = React.lazy(() => import("./split/Department/Department"));
const Designation = React.lazy(() => import("./split/Designation/Designation"));
const Employee = React.lazy(() => import("./split/Employee/Employee"));
const Locations = React.lazy(() => import("./split/Locations/Locations"));
const Timesheet = React.lazy(() => import("./split/Timesheet/Timesheet"));
const Keystoke = React.lazy(() => import("./split/Keystroke/Keystoke"));
const to_do_list = React.lazy(() => import("./split/ToDo-List/ToDoList"));
const sub_depart = React.lazy(() => import("./split/Department/SubDepartment"));
const Project_Management = React.lazy(() => import("./split/Project_Management/Project_Management"));
const Add_project = React.lazy(() => import("./split/Project_Management/Add_project"));
const Client = React.lazy(() => import("./split/Project_Management/Client"));
const TODO = React.lazy(() => import("./split/TODO"));
const RoleAccess = React.lazy(() => import("./split/Department/RoleAccess"));

const App = () => {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  // var [token, setToken] = useState(localStorage.getItem("token"))
  const [data, setData] = useState(false);
  // var routeList = JSON.parse(localStorage.getItem("token")?.role_access_data?.menu)
  const lang = localStorage.getItem("lang");



  let routeList = [];
  try {
    const parsedToken = token;
    routeList = parsedToken?.role_access_data?.menu || [];
  } catch (error) {
    console.error("Failed to parse token:", error);
  }

  // Adding login and root paths to routeList
  routeList = [...routeList, "/login", "/","/*","/dashboard"];

  const handleMethodInComponent = () => {
    // The method you want to trigger in Component
    console.log('Method in Component triggered');
    setData(true); // Example of setting state or doing something
  };
  const Dashboard = (Component) => {


    if(!token){
      return <Navigate to="/login" />
    }
    
    return (
      <div className="page">
        <Header />
        <Sidebar onIconClick={handleMethodInComponent}/>
        <div className="content">
          <div className="main-content ">
            <Component data={data} />
          </div>
        </div>
      </div>
    );
  };

  const LoginSecure = (Component) => {
    const Token = token;
    if (Token) {
      return <Navigate to="/dashboard" />;
    }
    return <Component />;
  };

  const routes = [

    { path: "/login", element: LoginSecure(Login)},
    { path: "/", element:  LoginSecure(Login) },
    { path: "/dashboard", element: Dashboard(Content)},
    { path: "/screen-shots", element: Dashboard(ScreenShots) },
    { path: "/application-track", element: Dashboard(Application_act) },
    { path: "/todo", element: Dashboard(TODO) },
    { path: "/organization", element: Dashboard(Organization) },
    { path: "/Url-track", element: Dashboard(URL_Track) },
    { path: "/organization_details", element: Dashboard(Organization_details) },
    { path: "/attendence", element: Dashboard(Attendence) },
    { path: "/department", element: Dashboard(Department) },
    { path: "/designation", element: Dashboard(Designation) },
    { path: "/employee", element: Dashboard(Employee) },
    { path: "/locations", element: Dashboard(Locations) },
    { path: "/timesheet", element: Dashboard(Timesheet) },
    { path: "/keystroke", element: Dashboard(Keystoke) },
    { path: "/todo-list", element: Dashboard(to_do_list) },
    { path: "/sub_depart", element: Dashboard(sub_depart) },
    { path: "/role-access", element: Dashboard(RoleAccess) },
    { path: "/project", element: Dashboard(Project_Management) },
    { path: "/client", element: Dashboard(Client) },
    { path: "/add-project", element: Dashboard(Add_project) },
    
    { path: "/*", element: <Navigate to="/dashboard" /> } 
    ];

  const filteredRoutes = routes.filter(route => routeList.includes(route.path));
  console.log("ROutes==>",filteredRoutes);

  const LoaderDash = () => {
    const location = useLocation();
    if(location.pathname != '/dashboard' || location.pathname != '/login'){
    return <Shimmer />;
    }
  };

  return (
    <Router>
      <Routes>
        {filteredRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
