import React, { useEffect, useState } from 'react';
import FetchData from '../fetch-api/Apifetch';
import { LIST_REPORT } from '../utils/ApiRoute';
import { handleProImageError } from '../utils/Function';
import Swal from 'sweetalert2'; 
import i18n from '../utils/i18n';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem('token')));
  const [EmpReport, setEmpReport] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    ListReportingEmp();
  }, []);



  const initialLang = localStorage.getItem('lang') || 'en'; // Default to 'en' if not set
  const [lang, setLang] = useState(initialLang);

  // Effect to handle future changes to the language
  useEffect(() => {
    document.documentElement.setAttribute('lang', lang); // Optionally, set the 'lang' attribute
    document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr'; // Apply the direction to the body
  }, [lang]);

  const toggleLanguage = () => {
    const newLang = lang === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLang); // Toggle between 'en' and 'ar'
    setLang(newLang); // Update the language state
    localStorage.setItem('lang', newLang); // Store the new language in localStorage
  };

  const toggleDataAttribute = () => {
    const htmlElement = document.documentElement;

    // Toggle the value of the data-toggled attribute
    if (htmlElement.getAttribute('data-toggled') === 'open') {
      htmlElement.setAttribute('data-toggled', 'close');
    } else {
      htmlElement.setAttribute('data-toggled', 'open');
    }
  };


  const handleIconClick = () => {
    if (window.innerWidth < 768) {
      toggleDataAttribute();
    }
  };
  const ListReportingEmp = async () => {
    try {
      const res = await FetchData(LIST_REPORT + `?id=${user.id}`, 'GET', null, false, false);
      console.log(res.data);
      setEmpReport(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out of your account!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'No, cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false 
    });

    if (result.isConfirmed) {
      
      console.log('User is about to be logged out');

      // Clear user data from local storage or cookies
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      // Redirect to login page
      window.location.href = '/login';
    }
  };

  return (
    <header className="app-header">
      <style>
  {`
    .swal2-popup {
      font-size: 1rem; /* Adjust font size of the popup */
    }

    .custom-confirm-button,
    .custom-cancel-button {
      border: none;
      border-radius: 0.375rem; /* Adjust border radius */
      font-size: 1rem; /* Font size of the button text */
      font-weight: bold; /* Font weight */
      padding: 0.75rem 1.25rem; /* Button padding */
      margin: 0.25rem; /* Space between buttons */
      cursor: pointer; /* Pointer cursor */
    }

    .custom-confirm-button {
      background-color: #433EBE; /* Custom color for "Yes" button */
      color: #fff; /* Text color */
    }

    .custom-confirm-button:hover {
      background-color: #433EBE; /* Darker shade for hover effect */
    }

    .custom-cancel-button {
      background-color: #6c757d; /* Custom color for "No" button */
      color: #fff; /* Text color */
    }

    .custom-cancel-button:hover {
      background-color: #5a6268; /* Darker shade for hover effect */
    }

    .swal2-styled.swal2-confirm {
      background-color: #433EBE !important; /* Ensure color is applied */
    }

    .swal2-styled.swal2-cancel {
      background-color: #6c757d !important; /* Ensure color is applied */
    }
  `}
</style>


      <nav className="main-header" aria-label="Global">
        <div className="main-header-container !px-[0.85rem]">
          <div className="header-content-left">
            {/* Start::header-element */}
            <div className="header-element">
              <div className="horizontal-logo">
                <a href="#" className="header-logo">
                  <img
                    src='./public/assets'
                    alt="logo"
                    className="desktop-logo"
                  />
                  <img
                    src="/assets/images/brand-logos/applogo.png"
                    alt="logo"
                    className="toggle-logo"
                    width={50}
                    height={50}
                  />
                  <img
                    src="../assets/images/brand-logos/desktop-dark.png"
                    alt="logo"
                    className="desktop-dark"
                  />
                  <img
                    src="../assets/images/brand-logos/toggle-dark.png"
                    alt="logo"
                    className="toggle-dark"
                  />
                  <img
                    src="../assets/images/brand-logos/desktop-white.png"
                    alt="logo"
                    className="desktop-white"
                  />
                </a>
              </div>
            </div>
           
            {/* End::header-element */}
            <div className="header-element !items-center">
              {/* Start::header-link */}
              <a
                aria-label="Hide Sidebar"
                className="sidemenu-toggle animated-arrow header-link hor-toggle horizontal-navtoggle inline-flex items-center"
                href="javascript:void(0);"
              >
                <i className="header-icon fe fe-align-left" onClick={()=>{handleIconClick()}}  />
              </a>
              <div className="main-header-center hidden lg:block">
                <input
                  className="form-control placeholder:!text-headerprimecolor placeholder:opacity-70 placeholder:font-thin placeholder:text-sm"
                  placeholder="Search for anything..."
                  type="search"
                />
                <button className="btn">
                  <i className="fa fa-search hidden md:block opacity-[0.5]" />
                </button>
              </div>
              {/* End::header-link */}
            </div>
          </div>

          <div className="header-content-right align-items-center gap-3">
           
            <div className="header-element items-center mr-2">
              <select
                className="form-select form-select-sm cursor-pointer border-0 text-dark dark:text-light"
                onChange={toggleLanguage}
                value={lang}
              >
                <option value="en">English</option>
                <option value="ar">عربي</option>
              </select>
            </div>

    
            <a onClick={handleLogout}>
              <img
                src={user?.emp_image || "/images/images.png"}
                style={{ width: '45px', height: '45px', borderRadius: '50%' }}
                onError={handleProImageError}
                alt="User Profile"
              />
            </a>
            <span><strong>{t('welcome')} {user?.name}</strong></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
